<template>
  <div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <!--begin: Wizard-->
        <div
          class="wizard wizard-1"
          id="kt_wizard_v1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Body-->
          <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
            <div class="col-xl-12">
              <!--begin: Wizard Form-->
              <form
                class="form"
                novalidate="novalidate"
                id="kt_classroom_add"
                @submit.stop.prevent="formOnsubmit()"
              >
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h5
                    class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg"
                  >
                    Form Tambah Kelas
                  </h5>

                  <!-- <div class="form-group">
                    <label class="col-xl-3 col-lg-4 col-form-label pl-0"
                      >Gambar Thumbnail</label
                    >
                    <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                      <div
                        class="image-input image-input-outline"
                        id="kt_profile_avatar"
                      >
                        <div class="image-input-wrapper">
                          <img :src="photo" alt="" />
                        </div>
                        <label
                          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="change"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Change avatar"
                        >
                          <i class="fa fa-pen icon-sm text-muted"></i>
                          <input
                            type="file"
                            name="profile_avatar"
                            accept=".png, .jpg, .jpeg"
                            @change="onFileChange($event)"
                          />
                          <input type="hidden" name="profile_avatar_remove" />
                        </label>
                        <span
                          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="cancel"
                          data-toggle="tooltip"
                          title="Cancel avatar"
                        >
                          <i class="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                        <span
                          class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="remove"
                          data-toggle="tooltip"
                          title="Remove avatar"
                          @click="current_photo = null"
                        >
                          <i class="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                      </div>
                    </div>
                  </div> -->
                  <b-form-group
                    id="input-group-name"
                    label="Nama Pelajaran:"
                    label-for="input-name"
                  >
                    <b-form-input
                      id="input-name"
                      v-model="form.name"
                      placeholder="Nama Pelajaran"
                    ></b-form-input>
                    <small class="text-danger">{{ error.name }}</small>
                  </b-form-group>
                  <b-form-group
                    id="input-group-room"
                    label="Nama Kelas:"
                    label-for="input-room"
                  >
                    <b-form-input
                      id="input-room"
                      v-model="form.room"
                      placeholder="Nama Kelas"
                    ></b-form-input>
                    <small class="text-danger">{{ error.room }}</small>
                  </b-form-group>
                  <b-form-group id="input-group-description">
                    <label for="input-description">Deskripsi Kelas:</label>
                    <b-form-textarea
                      id="input-description"
                      v-model="form.description"
                      placeholder="Deskripsi Kelas"
                      rows="4"
                      max-rows="8"
                    ></b-form-textarea>
                    <small class="text-danger">{{ error.description }}</small>
                  </b-form-group>
                  <div class="row">
                    <div class="col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="col-xl-3 col-lg-8 col-form-label pl-0"
                          >Gambar Thumbnail</label
                        >
                        <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                          <div
                            class="image-input image-input-outline"
                            id="kt_profile_avatar"
                          >
                            <div
                              class="image-input-wrapper image-input-wrapper-thumbnail"
                            >
                              <img :src="thumbnail" alt="" />
                            </div>
                            <label
                              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                              data-action="change"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Change avatar"
                            >
                              <i class="fa fa-pen icon-sm text-muted"></i>
                              <input
                                type="file"
                                name="profile_avatar"
                                accept=".png, .jpg, .jpeg"
                                @change="onThumbnail($event)"
                              />
                              <input
                                type="hidden"
                                name="profile_avatar_remove"
                              />
                            </label>
                            <span
                              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                              data-action="cancel"
                              data-toggle="tooltip"
                              title="Cancel avatar"
                            >
                              <i
                                class="ki ki-bold-close icon-xs text-muted"
                              ></i>
                            </span>
                            <span
                              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                              data-action="remove"
                              data-toggle="tooltip"
                              title="Remove avatar"
                              @click="onRemoveThumbnail()"
                            >
                              <i
                                class="ki ki-bold-close icon-xs text-muted"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <div class="form-group">
                        <label class="col-xl-3 col-lg-4 col-form-label pl-0"
                          >Gambar Header</label
                        >
                        <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                          <div
                            class="image-input image-input-outline"
                            id="kt_profile_avatar"
                          >
                            <div
                              class="image-input-wrapper image-input-wrapper-header"
                            >
                              <img :src="photo" alt="" />
                            </div>
                            <label
                              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                              data-action="change"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Change avatar"
                            >
                              <i class="fa fa-pen icon-sm text-muted"></i>
                              <input
                                type="file"
                                name="profile_avatar"
                                accept=".png, .jpg, .jpeg"
                                @change="onFileChange($event)"
                              />
                              <input
                                type="hidden"
                                name="profile_avatar_remove"
                              />
                            </label>
                            <span
                              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                              data-action="cancel"
                              data-toggle="tooltip"
                              title="Cancel avatar"
                            >
                              <i
                                class="ki ki-bold-close icon-xs text-muted"
                              ></i>
                            </span>
                            <span
                              class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                              data-action="remove"
                              data-toggle="tooltip"
                              title="Remove avatar"
                              @click="onRemove()"
                            >
                              <i
                                class="ki ki-bold-close icon-xs text-muted"
                              ></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-between border-top pt-10">
                  <div>
                    <b-button type="submit" variant="primary">Simpan</b-button>
                    <b-button
                      type="button"
                      class="ml-2"
                      variant="default"
                      @click="$router.push('/classrooms')"
                    >
                      Batal
                    </b-button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
              <!--end: Wizard Form-->
            </div>
          </div>
          <!--end: Wizard Body-->
        </div>
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<style scoped>
.image-input-wrapper-thumbnail {
  width: 232px !important;
  height: 232px !important;
}

.image-input-wrapper-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper-header {
  width: 280px !important;
  height: 120px !important;
}

.image-input-wrapper-header img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  name: "classroom-add",
  data() {
    return {
      form: {
        name: "",
        room: "",
        description: "",
        image_header: null,
        image_thumbnail: null,
      },
      error:{
        name: "",
        room: "",
        description: "",
        image_header: "",
        image_thumbnail: "",
      },
      current_thumbnail: null,
      current_photo: null,
      default_photo: "/img/blank.jpg",
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.image_header = e.target.files[0];

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onThumbnail(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        this.form.image_thumbnail = e.target.files[0];

        reader.onload = (event) => {
          this.current_thumbnail = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemove() {
      this.current_photo = null;
      this.form.image_header = null;
    },
    onRemoveThumbnail() {
      this.current_thumbnail = null;
      this.form.image_thumbnail = null;
    },
    async formOnsubmit() {

      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, "api/classrooms");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError( this.error, response.error.errors);
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/classrooms");
        this.$root.$emit("refreshClassroom");
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Classroom", route: "/classrooms" },
      { title: "Tambah Kelas" },
    ]);

    this.current_photo =
      "http://dev-elearning-api.wellmagicteam.com/storage/classrooms/classroom_h.png";
    this.current_thumbnail =
      "http://dev-elearning-api.wellmagicteam.com/storage/classrooms/classroom_t.png";
  },
  computed: {
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
    thumbnail() {
      return this.current_thumbnail == null
        ? this.default_photo
        : this.current_thumbnail;
    },
  },
};
</script>
